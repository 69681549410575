import { acceptHMRUpdate, defineStore } from 'pinia'
import type { User as AuthUser } from '@supabase/gotrue-js'
import { APP_NAME } from '~/config/app'
import type { User, UserProfile } from '~/supabase'

interface AppState {
  meta: {
    title: string
  }
  authUser: AuthUser | null
  user: User | null
  userProfile: UserProfile | null
}

export const useAppStore = defineStore('app', {
  state(): AppState {
    return {
      meta: {
        title: APP_NAME,
      },
      authUser: null,
      user: null,
      userProfile: null,
    }
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot))
}
